<template>
	<div class="newsCenter">
		<!-- header -->
		<homePage :urlFlag="4"></homePage>
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_news.png">
        </div>
        <div class="container article">
            <h1>{{ showNews.title }}</h1>
			<h5>2021-04-23</h5>
			<div class="articleDetail" v-html="showNews.content"></div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "newsCenter",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
			newsList: [
				{
					img: require('@/assets/img_servicedetails_1.png'),
					title: '9810出口西安首单放行',
					id: 1,
					content: `<p>跨境电商出口专列的开行，不仅为全国跨境电商货物出口提供了重要集散通道，也标志着中欧班列（西安）集结中心和中欧班列长安号跨境电商全国集结中心建设步入快车道。</p>
					<p>陕西省商务厅副厅长唐宇刚在致辞中表示，省商务厅将继续推进跨境电商“六体系两平台”建设，大力培育引进市场主体、开展省级海外仓认定、举办2021“丝路电商”合作（西安）圆桌会，不断加快全省跨境电商产业发展，切实把跨境电商作为提升地方经济外向度、增强外贸新动能的重要抓手。</p>
					<img src="@/assets/img_service_6.png" alt="">
					<p>我司一直积极主动拓展业务，向客户宣讲B2B铁路运输它相较于空运、海运，具有成本更低、运输时间更短的优势。并在1月就完成“9710/9810报关单模式”出口首单放行，完善我司在西安的所有服务测试。</p>
					<p>以跨境电商出口专列开行为起点，我司为顾客选择“最优线路”，依托由西安直达德国纽斯港的“德国快线”，向英国、西班牙、法国、意大利等西欧和南欧地区的企业海外仓调拨配送；创造“最高效率”。我司在海外多个城市提供海外仓服务，并且一直深挖B2B业务，我司海外荷兰鹿特丹，波兰华沙仓，德国汉堡仓作为海外仓模式在成都、重庆、西安等多地海关进行备案，为企业跨境电商出口业务，奠定坚实基础。专业的仓储团队最大限度提高货物国内段揽收、分拣、申报、查验时效，做到“揽-运-关”无缝连接。</p>
					<p>提前申报货物信息，货物即到即查即放行；提供“最优保障”，在中欧班列舱位资源稀缺的情况下，政府平台保障跨境电商货物优先订舱上列发运，同时西安车站海关制定周末弹性加班制度，确保货物24小时内完成清关查验；确保“最低成本”。免除客户一切场站作业费，一系列优惠措施，将促进“一带一路”沿线经贸合作的重要引擎。</p>`
				}, {
					img: require('@/assets/img_servicedetails_2.png'),
					title: '成都青白江-9810清单模式-首发成功',
					id: 2,
					content: `<p>四川欧城记跨境电子商务有限公司9810清单模式第一单成功发货。</p>
					<p>首批铁路出口9810清单模式通关放行，这意味着我司业务层面的创新从B2C扩展到了B2B领域，从跨境电商B2B出口“9710/9810 ”报关单模式，到跨境电商出口“9710/9810”清单模式的实际落地。</p>
					<p>8月13日，海关总署发布《关于扩大跨境电子商务企业对企业出口监管试点范围的公告》（2020年第92号），决定自9月1日起在包括成都在内的12个直属关区开展跨境电商B2B出口监管试点。</p>
					<img src="@/assets/img_service_1.png" alt="">
					<p>我司一直积极主动拓展业务，向客户宣讲B2B铁路运输它相较于空运、海运，具有成本更低、运输时间更短的优势。并在当月就完成“9710/9810报关单模式”出口首单测试。</p>
					<p>我司拥有专业搬运、装卸人员，可根据客户需求定制方案。设备完善，叉车数量多，每日可拆装20个以上集装箱。</p>
					<p>通过铁路运输的不断发展，我司在海外多个城市提供海外仓服务，并且一直深挖B2B业务，我司今年将荷兰鹿特丹，波兰华沙仓，德国汉堡仓作为海外仓模式在成都、重庆、西安等多地海关进行备案，为搭建多地开展跨境电商出口业务，奠定坚实基础。</p>
					<p>跨境电商B2B出口相对于9610出口，可运输批量的货物，到目的地或者海外仓再进行分拨配送，有利于客户体验与企业发展。下一步，我们将拓展更多的海外仓储企业参与到B2B出口业务，服务于更多的企业。</p>`
				}, {
					img: require('@/assets/img_servicedetails_3.png'),
					title: '德国馆开业',
					id: 3,
					content: `<p>2020年11月21日下午，德国国家馆开馆庆典在成都国际铁路港亚蓉欧国家（商品）馆区隆重举行。</p>
					<p>庆典活动出席嘉宾：</p>
					<p>德国驻成都总领事馆总领事鲁悟刚WolfgangRudischhauser</p>
					<p>领事戴英丽IngridDelfs</p>
					<p>成都市青白江区国际铁路港管理委员会主任郑泉峰</p>
					<p>瑞士驻成都总领事馆总领事高凯琳ConnyCamenzind</p>
					<p>法国驻成都总领事馆经济参赞法宇连JulienDefrance</p>
					<p>四川大学欧洲研究中心主任石坚</p>
					<p>四川农业大学德国研究中心主任沈倩岭</p>
					<p>一汽大众成都分公司总经理穆海乐HaraldMueller</p>
					<p>西门子工业自动化产品成都生产及研发基地CFO穆克力PhilippMuckley</p>
					<p>德国馆运营方欧城记跨境电子商务有限公司总经理苏昶</p>
					<p>德国国家馆总经理陈丽芸</p>
					<p>馆长席真</p>
					<img src="@/assets/img_service_6.png" alt="">
					<p>德国馆运营方欧城记跨境电子商务有限公司依托成都中欧班列、航运枢纽等功能、充分发挥企业自身海外一手货源、保税仓运营、国内线下门店等优势，力求把企业打造成跨境供应链行业标杆型企业，通过德国馆平台实现中德企业合作。</p>`
				}, {
					img: require('@/assets/img_servicedetails_4.png'),
					title: '正式启动“安自贸试点',
					id: 4,
					content: `<p>3月30日，四川自贸试验区青白江片区正式启动中欧班列（成都）--波兰罗兹“安智贸”项目试点，首家试点企业TCL王牌电器（成都）有限公司首批试点货物——价值197万余美元的液晶电视配件已办结所有海关手续，将搭乘中欧班列（成都）出口欧洲。作为“安智贸”试点企业，TCL王牌电器（成都）有限公司的货物刚经成都海关放行登上班列，相关信息便通过数据提前交换传送给波兰海关，中国海关查验过的货物，波兰海关原则上不予查验，能快速实现中欧海关数据交换、监管互认、执法互助。</p>
					<p>“安智贸”是“中欧安全智能贸易航线试点计划”的简称，是全球第一个全面实施WCO（世界海关组织）《全球贸易安全与便利标准框架》的国际合作项目，是中国-欧盟海关最具实质意义的重大合作项目。今年2月27日中欧安智贸第32次工作组会议上，成都成功加入安智贸试点，并确定了中欧班列（成都）—罗兹为“安智贸”试点航线。</p>
					<p>该条线路于2013年开通， 2018年成都至罗兹班列开行571列，占中欧班列(成都)开行总量的36%，是目前开行最稳定、发运班列最多的线路。</p>
					<p>中欧班列(成都)-罗兹线路加入“安智贸”试点，将为更多符合条件的企业带来更加便利化的通关环境，促进中欧班列（成都）打造更加顺畅、高效、便捷的国际贸易大通道；同时，也为推动成都海关与安智贸各成员国海关的合作奠定了基础。</p>
					<p>成都海关与波兰罗兹海关建立“点对点”直接合作机制，成都成为首个开展关际合作的内陆城市；与乌鲁木齐海关在班列开行、集拼集运等领域，与南宁海关在海铁联运通关等领域均达成常态化合作机制，促进了四川自贸试验区青白江片区平行进口车、跨境电商等货物的便利化通关。</p>
					<p>我司紧跟港区发展步伐，作为最早入驻企业拥有专业的团队，主营线路包含蓉欧中线、北线，直达波兰罗兹、马拉，德国纽伦堡、杜伊斯堡、荷兰蒂尔堡、意大利米兰、匈牙利布达佩斯、捷克布拉格、白俄罗斯明斯克等全欧主要站点，每周固定班列，实现全国提货，全欧派送，可代理清关，整柜、散柜、专列均可定制。</p>`
				}, {
					img: require('@/assets/img_servicedetails_5.png'),
					title: '助理西安中欧班列跨境电商发展',
					id: 5,
					content: `<p>2021年2月9日，中国内陆港，“钢铁驼队”整装待发。伴随汽笛长鸣，今年首列中欧班列长安号跨境电商出口专列从西安港——西安铁路集装箱中心站始发，一路向西，奔向德国纽斯。</p>
					<p>中欧班列+跨境电商，集结中心效应叠加，为跨境电商产业聚集发展提供服务最优、效率最高、成本最低的通道解决方案。</p>
					<p>当日上午11：18时许，随着和文全宣布中欧班列长安号跨境电商出口专列发车，满载41个集装箱饰品、箱包、服装、手机周边产品等日用百货的跨境电商出口专列缓缓驶出西安铁路集装箱中心站。班列将从霍尔果斯口岸出境，驰骋9400多公里，历经12天到达德国纽斯，为欧洲消费者送上来自中国的“新年礼物”。“本次专列搭载的商品主要来自义乌、深圳等地，搭乘长安号抵达德国纽斯后，转运至企业的比利时海外仓分拨至欧洲各国。”</p>
					<p>2020年11月，西安欧城记跨境电子商务有限公司落地国际港务区。</p>
					<p>公司总经理张立平出席活动，接受媒体采访时提到“从12月开始筹备，短短1个多月时间里，我们已经通过中欧班列长安号发运了20个集装箱。”“随着跨境电商全国集结中心建设的推进，2021年，我们力争进出口贸易额达到10亿元。”表达了我司助力中欧班列的决心和信心。</p>
					<p>我司抓住西安港新经济机遇，创新贸易方式，多渠道发展。2020年在西安迅速成立欧城记西安分公司，助理中欧班列（西安）跨境电商在全国集结中心的号角打响，助力跨境电商产业高地，助推中欧班列（西安）集结中心高质量发展。</p>`
				}
			],
			showNews: {}
		}
	},
	methods: {
	},
	mounted() {
		this.showNews = this.newsList[this.$route.query.id - 1]
	},
};
</script>

<style scoped>
.container {
    margin-top: 80px;
    margin-bottom: 100px;
}
</style>
